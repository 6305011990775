import { transform } from 'framer-motion';
import HexGrid from '../tools/HexGrid';
import { getContainerStyle } from '../../utils/variable-extractor';

const SetupImageHexGrid = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData
}) => {

    if (variables.variablesValues) {
        variables = variables.variablesValues;
    }

    const containerStyle = {
        ...getContainerStyle("33fde691-96f6-41c6-be40-f072b3d60f6e", template, cards, card, variables, templateData, null),
        width: "78%",
        height: "auto",
        margin: "3rem 0  2rem",
        transform: "scale(1.2)",
        overflow:"hidden"
    };

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3 paddingHexGrid" style={containerStyle}>
            <HexGrid 
                variables={variables}
                template={template}
                cards={cards}
                card={card}
                templateData={templateData}
                variable="54b9a0e5-42cd-458c-bb4a-1894558e54ed"
            />
        </div>
    );
}

export default SetupImageHexGrid;
