import './_templates.scss'
import { findModuleVariables, findNestedVariable, findVariableOrDefaultToTempateValue } from '../../utils/variable-extractor';
import { BLOB_URL, V_NUMBER } from '../../utils/constants';
import ModuleSelector from '../modules/_module-selector';
import ChaptersIcons from '../icons/ChaptersIcons';
import VariableValueExtractor from "../utils/VariableValueExtractor";


const InvestigationFirstPage = ({
    currentCard,
    template,
    cards,
    deckId,
    templateData,
    ctaId,

}) => {

    const parentId = currentCard._id;

    const backgroundImage = BLOB_URL + findVariableOrDefaultToTempateValue("d6afd089-baab-4964-af31-d475b4d1fa48", template, cards, currentCard, currentCard.variablesValues, templateData).value;

    return (
        <div className="investigation-first-page w-full h-full flex-col bg-center bg-cover flex  items-center pt-10 overflow-y-auto"
            style={{ backgroundImage: `url(${backgroundImage})`,backgroundColor:"rgb(35, 31, 32)" }}
        >
            <div className="flex flex-col items-center w-full px-4 text-center">
                <img src={BLOB_URL + "upload/images/64ed011e17b7622c4d323360/Booklets/icons/Ank+Line.webp" + "?v=" + V_NUMBER} style={{ maxWidth: "300px" }} alt="ank-line" key="img-1" />
                    <VariableValueExtractor variableId={"6b20a631-cdd5-419b-943c-cb7bd21fba5f"}
                        template={template}
                        cards={cards}
                        card={currentCard}
                        variables={currentCard.variablesValues}
                        templateData={templateData}
                        renderId={null}
                        isLink={null}
                        ctaId={ctaId}
                        parentId={parentId}/>
                <img src={BLOB_URL + "upload/images/64ed011e17b7622c4d323360/Booklets/icons/Line+Tips.webp" + "?v=" + V_NUMBER} style={{ maxWidth: "300px" }} alt="ank-line" key="img-2" />
            </div>

            <div className='background-image flex items-center justify-center' style={{
                backgroundImage: `url(${BLOB_URL}${findVariableOrDefaultToTempateValue("ecc4c948-a3b9-4772-94a0-e6a26cabbbfc", template, cards, currentCard, currentCard.variablesValues, templateData)?.image})`,
                height: '90px',
                width: '90px'
            }}>
                <div className='mt-2'>
                    <ChaptersIcons name={findNestedVariable("b5c8aef3-dd63-45ef-b9a2-6e9b79439b5c", currentCard.variablesValues).value}
                    color='white'
                    width='80px'
                    height='80px'/>
                </div>
            </div>

            <div className='flex flex-col text-justify' style={{ width: '80%' }}>
                {findModuleVariables("42dd4da9-a477-46b7-bade-6426250db73d", currentCard).map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={currentCard}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            fullWidth={true}
                            separatorColor="#f1e4d7"
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default InvestigationFirstPage;