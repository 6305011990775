import './_templates.scss'
import { findModuleVariables, findVariableOrDefaultToTempateValue, getContainerStyle } from '../../utils/variable-extractor';
import { BLOB_URL, CONTENT_WIDTH, DEFAULT_ORANGE_CODE, V_NUMBER} from '../../utils/constants';
import ModuleSelector from '../modules/_module-selector';
import { FONT_SIZE_MP } from '../../utils/libs';
import VariableValueExtractor from "../utils/VariableValueExtractor";


const ChapterConclusionEnding = ({
    currentCard,
    template,
    variables,
    card,
    cards,
    templateData,
    deckId,
    ctaId
}) => {

 
    const parentId = currentCard._id;
    
    let backgroundImage = BLOB_URL + "upload/images/64ed011e17b7622c4d323360/Storybook/Storybook_Marble_Black.webp";

    const containerStyle = {
        ...getContainerStyle("796673dd-a965-41c8-86f6-a2f994aa64bf", template, cards, currentCard, currentCard.variablesValues, templateData, null),
        padding: "7px",
        width: CONTENT_WIDTH,
        marginTop: "20px",
        textAlign: "center"
    }

    return (
        <div className="cover-prologue-front w-full h-full flex flex-col bg-center bg-cover overflow-y-auto force-font-size items-center py-10"
            style={{ backgroundImage: `url(${backgroundImage})`, paddingTop:"50px", paddingBottom:"20px", backgroundColor:"rgb(35, 31, 32)" }}
        >
            <div className=" flex flex-col grow items-center">
                <div className='w-full text-center mb-2'>
                    <h5 style={{ fontFamily: "CrimsonPro", color: DEFAULT_ORANGE_CODE, fontWeight: "bold", fontSize: `${8 * FONT_SIZE_MP}px` }}>
                        <VariableValueExtractor variableId={"22c19678-71f0-48e7-ab79-f63781c10870"}
                            template={template}
                            cards={cards}
                            card={currentCard}
                            variables={currentCard.variablesValues}
                            templateData={templateData}
                            renderId={null}
                            isLink
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </h5>
                    <h1  style={{ fontFamily: "Oswald-Bold", color: "#f1e4d7", fontWeight: "bold", fontSize: `${20 * FONT_SIZE_MP}px` }}>
                        CONCLUSION
                    </h1>
                </div>


                <div style={containerStyle}>
                    <div className={"mb-2"}>
                        <div className='py-2 custom-padding'>
                            <VariableValueExtractor variableId={"3eb8e9c7-f1c0-46ae-b8a4-399ea8bc5aa7"}
                                template={template}
                                cards={cards}
                                card={currentCard}
                                variables={currentCard.variablesValues}
                                templateData={templateData}
                                renderId={"f02e45f5-4432-479a-bb4d-06393207db03"}
                                isLink
                                ctaId={ctaId}
                                parentId={parentId}/>
                        </div>
                        <div>
                            <VariableValueExtractor variableId={"3b0596d3-eb6f-47fe-a021-f1b9d16402e7"}
                                template={template}
                                cards={cards}
                                card={currentCard}
                                variables={currentCard.variablesValues}
                                templateData={templateData}
                                renderId={"07a9e33b-de4a-4d69-b130-7513544e02ae"}
                                isLink
                                ctaId={ctaId}
                                parentId={parentId}/>
                        </div>


                    </div>
                </div>

            </div>
        </div>
    )
}

export default ChapterConclusionEnding;