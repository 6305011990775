import './_templates.scss'
import { findModuleVariables, findVariableOrDefaultToTempateValue, getContainerStyle } from '../../utils/variable-extractor';
import { BLOB_URL, CONTENT_WIDTH, DEFAULT_ORANGE_CODE, V_NUMBER } from '../../utils/constants';
import ModuleSelector from '../modules/_module-selector';
import VariableValueExtractor from "../utils/VariableValueExtractor";


const InterludeEndSectionPageRight = ({
    currentCard,
    template,
    cards,
    templateData,
    deckId,
    ctaId,
}) => {

    const parentId = currentCard._id;

    let backgroundImage = BLOB_URL + 'upload/images/64ed011e17b7622c4d323360/Storybook/Storybook_Marble_White.webp';

    const bottomContainerStyle = {
        ...getContainerStyle("b34ea574-bb58-446c-861b-9883c778aa92", template, cards, currentCard, currentCard.variablesValues, templateData, null),
        padding: "7px",
        width: CONTENT_WIDTH,
        marginTop: "20px"
    }

    return (
        <div className="cover-prologue-front w-full h-full flex flex-col bg-center bg-cover overflow-y-auto items-center py-10"
            style={{ backgroundImage: `url(${backgroundImage})`, paddingTop: "50px", paddingBottom: "20px" }}>


                <div className='flex items-center force-font-size-25' style={{color:DEFAULT_ORANGE_CODE}}>
                     <div className='mr-3' style={{fontWeight:"bold"}}>-----</div>
                        <VariableValueExtractor variableId={"3eb8e9c7-f1c0-46ae-b8a4-399ea8bc5aa7"}
                            template={template}
                            cards={cards}
                            card={currentCard}
                            variables={currentCard.variablesValues}
                            templateData={templateData}
                            renderId={"5fd3850d-c55e-4f06-9f2c-46f4f6388144"}
                            isLink={null}
                            ctaId={ctaId}
                            parentId={parentId} />
                        <div className='ml-3' style={{fontWeight:"bold"}}>-----</div>
                </div>
            <div className='w-full h-full flex flex-col items-center  force-font-size'>

                {findModuleVariables("4ca9105a-fe49-466d-8748-e0885a8b75eb", currentCard).map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={currentCard}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            noStyle={true}
                        />
                    )
                })}
                {findModuleVariables("097cbf93-f94a-4978-9da6-902dfed35639", currentCard).map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={currentCard}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            noStyle={true}
                        />
                    )
                })}

                <div style={bottomContainerStyle}>
                    <div className='mb-3'>
                        <VariableValueExtractor variableId={"60024739-1a04-4a28-9ce6-f1459118fa1b"}
                            template={template}
                            cards={cards}
                            card={currentCard}
                            variables={currentCard.variablesValues}
                            templateData={templateData}
                            renderId={null}
                            isLink={null}
                            ctaId={ctaId}
                            parentId={parentId} />
                    </div>
                    <VariableValueExtractor variableId={"a63538d6-9bc8-4451-9d08-55bcadf69538"}
                        template={template}
                        cards={cards}
                        card={currentCard}
                        variables={currentCard.variablesValues}
                        templateData={templateData}
                        renderId={null}
                        isLink={null}
                        ctaId={ctaId}
                        parentId={parentId} />
                    {findModuleVariables("e56dc428-7ed2-4811-bbb3-24b25fdb05fc", currentCard).map((variable, index) => {
                        return (
                            <ModuleSelector
                                key={index}
                                variable={variable}
                                card={currentCard}
                                cards={cards}
                                deckId={deckId}
                                templateData={templateData}
                                noStyle={true}
                            />
                        )
                    })}
                </div>
                <div className='bottom-section w-full'>
                    <div className='flex flex-col items-center justify-center background-image w-100 p-5' style={{backgroundImage: `url("${BLOB_URL}upload/images/64ed011e17b7622c4d323360/Booklets/Background/Marble_Black_DoublePage.webp?v=${V_NUMBER}"})`
                    }}>
                        <div className='background-overlay flex flex-col items-center justify-center text-center' style={{width: CONTENT_WIDTH}}>
                            <VariableValueExtractor variableId={"22c19678-71f0-48e7-ab79-f63781c10870"}
                                template={template}
                                cards={cards}
                                card={currentCard}
                                variables={currentCard.variablesValues}
                                templateData={templateData}
                                renderId={'0050f22b-7c7e-499d-83a0-9abdd8e7d65c'}
                                ctaId={ctaId}
                                parentId={parentId} />

                            <div className='flex justify-center' style={{ width: "100%" }}>
                                <img src={BLOB_URL + "upload/images/64ed011e17b7622c4d323360/Booklets/icons/Ank+Line.webp" + "?v=" + V_NUMBER} style={{ maxWidth: "300px" }} alt="ank-line" />
                            </div>

                            {findModuleVariables("f17b4bff-61ea-41e2-abd8-455c6d86ecf8", currentCard).map((variable, index) => {
                                return (
                                    <ModuleSelector
                                        key={index}
                                        variable={variable}
                                        card={currentCard}
                                        cards={cards}
                                        deckId={deckId}
                                        templateData={templateData}
                                        noStyle={true}
                                        fullWidth={true}
                                    />
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InterludeEndSectionPageRight;