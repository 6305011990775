import { BLOB_URL, V_NUMBER} from '../../utils/constants';
import { findNestedVariable } from '../../utils/variable-extractor';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const SetupPlayerToken = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    // XXX: "73a1b176-a04b-4188-8a2f-31be7bcfed34"


    if(variables && variables.variablesValues){
        variables = variables.variablesValues;
    }    

    var image = findNestedVariable("7e1ea13b-d5b0-4bc3-a529-7fc71145dd43", variables);

    var token = templateData.tokens.find(_=>_._id === image.value);
    var url = token.image;


    return (
        <div name={`${template._id} - ${template.name}`} className="flex flex-col flex-wrap mx-2 content-center">
        <div className='flex flex-col text-center items-center' style={{width:"fit-content", fontFamily:"CrimsonPro-Bold"}}>
                <img src={BLOB_URL + url + "?v=" + V_NUMBER} width={100} alt="token"/>
                <VariableValueExtractor variableId={"22bf8a5d-8f50-4b30-b791-7ba5b97dddf0"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
            </div>
        </div>
    )
}

export default SetupPlayerToken;