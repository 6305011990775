import { CONTENT_WIDTH } from "../../utils/constants";
import { getContainerStyle } from "../../utils/variable-extractor";
import VariableValueExtractor from "../utils/VariableValueExtractor";

const XxxRuleGrayChap14Restart = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if(variables && variables.variablesValues){
        variables = variables.variablesValues;
    }

    if(!template) return (<div></div>);

    let renderContainer = template?.render[0]?.children[0]?.uuid;
    let textRender = template?.render[0]?.children[0]?.children[0]?.uuid

    const masterContainerStyle = {
        ...getContainerStyle(renderContainer, template, cards, card, variables, templateData, null),
        padding:"7px"
    }

    return (

        
        <div name={`${template._id} - ${template.name}`} className="mt-3 flex " style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null }}>
            <div className='w-full flex flex-col justify-center items-left my-1'>
                <VariableValueExtractor variableId={textRender}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    ctaId={ctaId}
                    parentId={parentId}/>
              
            </div>
        </div> 
      
    )
}

export default XxxRuleGrayChap14Restart;