import { getContainerStyle} from '../../utils/variable-extractor';
import VariableValueExtractor from "../utils/VariableValueExtractor";


const TemplateStorybookConclusionNoDiscardClueTokens = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    
    if (variables && variables.variablesValues) {
        variables = variables.variablesValues;
    }

    let containerRender = template.render[0];

    const masterContainerStyle = {
        ...getContainerStyle(containerRender?.uuid, template, cards, card, variables, templateData, null),
        paddingTop: "4px",
        paddingBottom: "4px",
        paddingLeft: "7px",
        paddingRight: "7px"
    }

    const ctaVariableId = template.render[0]?.children[1]?.variable;
    const textVariable = template.render[0]?.children[1]?.uuid;

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3 flex justify-center" style={{ ...masterContainerStyle }}>
            <div className='flex'>
                <VariableValueExtractor variableId={textVariable}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
           </div>
        </div>
    )
}

export default TemplateStorybookConclusionNoDiscardClueTokens;