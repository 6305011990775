import './_content-table.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp, faList, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, Link } from 'react-router-dom';

import { ReactComponent as HomeSvg } from '../../components/icons/DefaultIconSvgs/Home.svg';

import { findModuleVariables } from '../../utils/variable-extractor';
import ChaptersIcons from '../../components/icons/ChaptersIcons';
import { useContext, useState } from 'react';
import {
    Collapse
} from "@material-tailwind/react";
import { getDelimitationPageNumber, getPageNumber } from '../../utils/frogs-text-generator';
import { useTranslation } from 'react-i18next';
import TranslationsContext from '../../stores/translationsContext';
import { orderCards } from '../../utils/libs';


export function ContentTableMenu({
    setShowContentTablePage
}) {
    const handleContentTableClick = () => {
        setShowContentTablePage(true);
    }

    return (
        <div className='content-table-menu-content' onClick={() => { handleContentTableClick() }}>
            <FontAwesomeIcon icon={faList} /> <span className='ms-2'>Table of Contents</span>
        </div>
    )
}

const handleSpecialCases = (cardName, parentName) => {
    
    if(parentName.toLowerCase() === "sabbatthug"){
        return cardName.substring(0, cardName.indexOf("Sabbat Thug"));
    }


    return cardName;
}

const getPrefix = (name) => {

    //Exception pour Chapitre Tremere IN au lieu de FD dans section FD
    if (name.indexOf("FinalDeduction_BotchedInvestigation") > -1) return "FD.";
    
    if (name.indexOf("Investigation") > -1) return "IN.";

    if (name.indexOf("Dialogue") > -1) return "D.";

    if (name.indexOf("Dialog") > -1) return "D.";
    
    if (name.indexOf("Event") > -1) return "E.";

    if (name.indexOf("FinalDeduction") > -1) return "FD.";

    if (name.indexOf("Guess") > -1) return "G.";

    return "";
}

const RemoveSpecialCaseName = (name) => {
    name = name.replace("I N1", "");
    name = name.replace("I N2", "");
    name = name.replace("I N3", "");
    name = name.replace("I N4", "");
    name = name.replace("I N5", "");
    name = name.replace("I N6", "");
    name = name.replace("I N7", "");

    return name;
}


export function ContentTablePage({
    setShowContentTablePage,
    variables,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    cardsOrder
}) {
    const { translationsCards} = useContext(TranslationsContext);
    const { i18n } = useTranslation();
    const navigate = useNavigate();

    const handleClose = () => {
        setShowContentTablePage(false);
    }

    const handleClick = (pageId) => {
        navigate(`/${deckId}/${pageId}`);
        setShowContentTablePage(false);
    }

    const allCtas = findModuleVariables("42dd4da9-a477-46b7-bade-6426250db73d", card);

    const intro = cards.find(_=>_.name.toLowerCase().includes("intro"));
    const conclusion = cards.find(_=>_.name.toLowerCase().includes("conclusion"));
    if(intro === null) intro = cards[1];
    if(conclusion === null) conclusion = cards[cards.length - 2];

    return (
        <div className="content-table-page">
            <div className='title'>
                <div>
                    <FontAwesomeIcon icon={faList} /> <span className='ms-2'>Table of Contents</span>
                </div>

                <div className='line'>
                    <img src='/images/utils/white-down-triangle.png' alt="1"></img>
                </div>
            </div>

            <div className='sections'>
                {allCtas.map((variable, index) => {
                    if (variable.ctaId === '6526a49c838075da23c1be9e')
                        return (
                            <div key={index} className='w-full'>
                                <ItemTitle
                                    variable={variable}
                                    card={card}
                                    cards={cards}
                                    deckId={deckId}
                                    templateData={templateData}
                                    setShowContentTablePage={setShowContentTablePage}
                                    cardsOrder={cardsOrder}
                                />
                            </div>
                        )

                    return (
                        <div key={index} className='w-full'>
                            <ItemChild
                                index={index}
                                variable={variable}
                                card={card}
                                cards={cards}
                                deckId={deckId}
                                templateData={templateData}
                                setShowContentTablePage={setShowContentTablePage}
                                cardsOrder={cardsOrder}
                            />
                        </div>
                    )
                })}

                
                <div className='section-item flex flex-col' style={{alignItems:'start'}}>
                    <div className='mt-5 underline' style={{ fontSize: "16px" }}>
                        <div className='cursor-pointer mb-2' onClick={() => { handleClick(intro._id) }}>Introduction</div>
                    </div>
                    <div className='mt-3 underline' style={{ fontSize: "16px" }}>
                        <div className='cursor-pointer mb-2' onClick={() => { handleClick(conclusion._id) }}>Conclusion</div>
                    </div>
                </div>
            </div>

            <div className='button-container'>
                <a onClick={() => { handleClose() }} href="#1">
                    <HomeSvg width={'75px'} height={'75px'} />
                </a>
            </div>
        </div>
    )
}

function ItemTitle({
    variable,
    cards,
    card,
    deckId,
    templateData,
    setShowContentTablePage,
    cardsOrder
}) {

    const navigate = useNavigate();
    const variables = variable.variablesValues;
    const [showAllSubItems, setShowAllSubItems] = useState(false);

    const extractName = (str, category) => {
        str = str.replace(category, "");
        str = str.replace(/_/g, ' ');
        str = str.replace(/&/g, 'And');
        str = str.replace(/([A-Z])/g, ' $1');
        str = str.trim().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

        return str;
    }

    const { translationsCards} = useContext(TranslationsContext);
    const { i18n } = useTranslation();

    var page = variables["e6c2a780-7f54-4176-9ea0-c6e56ae01d65"] ? variables["e6c2a780-7f54-4176-9ea0-c6e56ae01d65"].value : '';
    if(!page || page === '') return;
    var enumeratorV = variables["25db691f-edbb-48e3-a090-52903a634599"];
    var enumerator = templateData.settings.enumerators.find(_ => _._id === enumeratorV.enumeratorId);
    var enumeratorValue = enumerator.values.find(_ => _.uuid === enumeratorV.value);
    var name = enumeratorValue.name;

    const nameFormatedLower = name.toLowerCase().replaceAll(" ", "") + "_";


    let dialogueCards = orderCards(cards.filter(_ => _.name.toLowerCase().indexOf(nameFormatedLower) > -1), cardsOrder);
    
    const subItems = dialogueCards.map(c => { 

        const titleEvent = c?.variablesValues?.["d1261fff-4ebb-4472-a18b-1a9621fb20ca"]?.value ||'';

        let name = extractName(c.name.substring(c.name.toLowerCase().indexOf(nameFormatedLower) + nameFormatedLower.length));        

        if (i18n.language === "fr"){

            const translationCard = translationsCards.find(o =>{
                if(o.card === c._id){
                    return o;
                }
            })
            
            if(translationCard){
                name = extractName(translationCard.name.substring(translationCard.name.toLowerCase().indexOf(nameFormatedLower) + nameFormatedLower.length));
            }
        }

        const cardNumber = getDelimitationPageNumber(c._id, cards);
        if(name === cardNumber) name = "";

        return {
            fullName: c.name,
            name: cardNumber ? `${getPrefix(c.name)}${cardNumber} ${name}` : name,
            id: c._id,
            titleCard : `${getPrefix(c.name)}${cardNumber} ${titleEvent}`
        }
    }).filter(_ => !_.fullName.toLowerCase().includes('section') && !_.fullName.toLowerCase().includes(' cover') && !_.fullName.toLowerCase().includes('cover ')&& !_.fullName.toLowerCase().includes('_cover') && !_.fullName.toLowerCase().includes('cover_'));

    const handleClick = (pageId) => {
        navigate(`/${deckId}/${pageId ? pageId : page}`);
        setShowContentTablePage(false);
    }
    
    const hideItems = ["INVESTIGATION", "DIALOGUE"].indexOf(enumeratorValue.name) === -1;

    return (
        <><div
            key={"title"}
            className='section-title items-center'
        >
            <div className='mr-4'
                onClick={() => { handleClick() }}>
                {enumeratorValue.name}
            </div>
            { hideItems ? (subItems && subItems.length) ? (
                <div className='cursor-pointer flex flex-col items-center' key={"1"} style={{ width: "30px" }} onClick={() => setShowAllSubItems(!showAllSubItems)}>
                    {showAllSubItems ? (<FontAwesomeIcon icon={faCaretUp} />) : (<FontAwesomeIcon icon={faCaretDown} />)}
                </div>
            ) : <></> : <></> }
        </div>
            <Collapse open={showAllSubItems}>
                <div className='mb-5 ms-3 underline' style={{ fontSize: "16px" }}>
                    {subItems.map((page, index) => {
                        return (<div className='cursor-pointer mb-2' key={index} onClick={() => { handleClick(page.id) }}>{page.titleCard ? page.titleCard : RemoveSpecialCaseName(page.name)}</div>);
                    })}
                </div>
            </Collapse>
        </>

    )
}

function ItemChild({
    variable,
    cards,
    card,
    deckId,
    templateData,
    setShowContentTablePage,
    cardsOrder,
    index
}) {

    const navigate = useNavigate();
    const [showAllSubItems, setShowAllSubItems] = useState(false);

    const extractName = (str, category) => {
        str = str.replace(category, "");
        str = str.replace(/_/g, ' ');
        str = str.replace(/&/g, 'And');
        str = str.replace(/([A-Z])/g, ' $1');
        str = str.trim().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

        return str;
    }

    const removeSpecialCaseCards = (subItems) => {
        // console.log(subItems);

        const idsToRemove = [
            "6531241d779575d19d092cfb", // Chapter 2 remove event in dialog
            "65396ea0caf6418cb0f7663b", // Tremere remove event in dialog
            "654be10a451d7e555c8d7573", // Chapitre 10 event in dialog
            "65678c14b7d785d3ba80c0f0", // Obscure Past2 - Tablecontent in Table
            "654be117451d7e555c8d76dd",//Chapitre 10 - Bathroom - E9
            "656e027189231f88c30817c8", // "Masquerade Breach 2" - E39 in Dialogue
            "656e01d689231f88c307e78d",//  "Masquerade Breach 2" - E4 in Locker 
        ];
        
        subItems = subItems.filter(_ => idsToRemove.indexOf(_.id) == -1);

        return subItems;
    };

    //On exclu les carte Cover sauf celle la car elle contient Cover 
    const specialCards = [
        'Dialogue_ClarenceBleaker_JudgingBooksAndCovers', //Chapter Hecata2
        'Investigation_CoveredPiano_1',
        'Investigation_CoveredPiano_2',
        'Investigation_CoveredPiano_3',
        'Investigation_CoveredPiano_4',
        'Investigation_CoveredPiano_5',
    ];

    const variables = variable.variablesValues;
    if(!variables) return;
    var page = variables["e6c2a780-7f54-4176-9ea0-c6e56ae01d65"] ? variables["e6c2a780-7f54-4176-9ea0-c6e56ae01d65"].value : '';
    var name = variables["924979c6-869d-4c98-a60d-2f5229f8da7a"] ? variables["924979c6-869d-4c98-a60d-2f5229f8da7a"].value : '';
    var iconData = variables["e52ccd25-02e2-48a9-802a-850a8d88ade6"];

    if(page === '' || name === '') return;

    const nameFormated = name.toLowerCase().replaceAll(" ", "").replaceAll('’',"'");  
   
    let dialogueCards = orderCards(cards.filter(_ => _.name.toLowerCase().indexOf(nameFormated + "_") > -1 || _.name.toLowerCase().indexOf(nameFormated) > -1), cardsOrder);

    if(name.toLowerCase() === 'den' || name.toLowerCase() === 'male fan' || name.toLowerCase() === 'zimmer' || name.toLowerCase() === 'neighbor'){
        dialogueCards = orderCards(cards.filter(_ => _.name.toLowerCase().indexOf("_" + nameFormated ) > -1), cardsOrder);
    }
    
    let subItems = dialogueCards.map(c => {          
        let name = extractName(c.name.substring(c.name.toLowerCase().indexOf(nameFormated)).replace(nameFormated, "").replace("_", " "));
        if (name.indexOf("  ")) name = name.substring(name.indexOf("  ")).trim();
        
        const cardNumber = getDelimitationPageNumber(c._id, cards);
        if(name === cardNumber) name = "";

        const titleCard = c?.variablesValues?.["d1261fff-4ebb-4472-a18b-1a9621fb20ca"]?.value||'';

        //Chapitre Ministry 1 comme chief aussi dans le nom
        if ( c.deck === "6567552fb7d785d3ba7d4573"){

            if (c.name === "Investigation_ChiefsTent_1" || c.name === "Investigation_ChiefsTent_2") {
                c.name = c.name.replace("ChiefsTent", "chief'stent");
            }
        }
        
        return {
            fullName: c.name,
            name: handleSpecialCases(cardNumber ? `${getPrefix(c.name)}${cardNumber} ${name}` : name, nameFormated),
            id: c._id,
            titleCard: c.name.includes("Dialogue") || c.name.includes("Dialog") 
                        ? `${getPrefix(c.name)}${cardNumber} ${titleCard}`
                        : `${getPrefix(c.name)}${cardNumber}`
     
        }      
    }).filter(_ => 
        !_.fullName.toLowerCase().includes('section') && 
        (!_.fullName.toLowerCase().includes('cover') || specialCards.includes(_.fullName)) 
    );

    subItems = removeSpecialCaseCards(subItems);

    const handleClick = (pageId) => {
        navigate(`/${deckId}/${pageId ? pageId : page}`);
        setShowContentTablePage(false);
    }   

    return (
        <>
            <div
                key={`item-${index}`}
                className='section-item'
                style={iconData && iconData.value ? { marginLeft: "-11px", minHeight: '30px' } : { minHeight: '30px', marginBottom: "15px" }}
            >
                <div className='mr-3' onClick={() => { handleClick() }}>
                    {iconData && iconData.value && (<ChaptersIcons name={iconData.value} />)}{name}
                </div>


                {(subItems && subItems.length) ? (
                    <div className='cursor-pointer flex flex-col items-center' style={{ width: "30px" }} onClick={() => setShowAllSubItems(!showAllSubItems)}>
                        {showAllSubItems ? (<FontAwesomeIcon icon={faCaretUp} />) : (<FontAwesomeIcon icon={faCaretDown} />)}
                    </div>
                ) : <></>}
            </div>

            <Collapse open={showAllSubItems}>
                <div className='mb-5 ms-3 underline' style={{ fontSize: "16px" }}>
                    {subItems.map((page, i) => {
                    
                        //Enlever les Int dans dialogue ou dans d'autre section
                        const hiddenIds = [
                            "656795afb7d785d3ba81c77c",// In1 Ministry 1
                            "656795e6b7d785d3ba81c94a",// In2 Ministry 1
                            "656795f6b7d785d3ba81ca85", //"Event_Caged" Ministry1
                            "655d207e58c548552e65ea2e",// Chapitre 29 int 1 side street
                            "655d25b458c548552e668d56",// Chapitre 29 int 2 side street
                            "655d25b758c548552e668db0",// Chapitre 29 int 3 side street
                        ]
                        if (name === "Chief" && hiddenIds.includes(page.id) && page.name.includes("IN.")) { return null;} 
                        if (name === "Table" && hiddenIds.includes(page.id)) {return null;} 
                        if (name === "Cage" && hiddenIds.includes(page.id)) {return null;} 
                        if (name === "Sid" && hiddenIds.includes(page.id) && page.name.includes("IN.")) {return null;} 

                        //Prologue Toreador - Exclusif Chapitre Dialogue Alex Simard - hide Dialogue Card
                        const dialogueAlexSimard = [ "651db16c838075da23bf85bc","651db190838075da23bf8623","651db191838075da23bf863f",
                            "651db193838075da23bf865c","651db194838075da23bf867a","651db195838075da23bf8699","651db196838075da23bf86b9" ]
                        if (name === "Alex Simard" && dialogueAlexSimard.includes(page.id)) {return null;} 

                        //Dialogue dans Desk kMasquerade Breach 2 
                        const deskMasqueradeBreach2  = ["656df63789231f88c3050fda","656df68989231f88c305105c","656df68d89231f88c3051094","656df69089231f88c30510a5","656df69289231f88c30510b7", 
                            "656df69489231f88c30510ca","656df69789231f88c30510df","656df69a89231f88c305119d"]
                        if (name === "Desk" && deskMasqueradeBreach2.includes(page.id)) {return null;} 


                        return (<div key={i} className='cursor-pointer mb-2' onClick={() => { handleClick(page.id) }}>{page.titleCard ? page.titleCard : RemoveSpecialCaseName(page.name)}</div>);
                    })}
                </div>
            </Collapse>
        </>
    )
}