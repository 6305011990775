import { CONTENT_WIDTH } from '../../utils/constants';
import { getContainerStyle, findModuleVariables } from '../../utils/variable-extractor';
import ModuleSelector from "./_module-selector";
import VariableValueExtractor from "../utils/VariableValueExtractor";

const IfYouHaveDisciplineSandbox = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables && variables.variablesValues) {
        variables = variables.variablesValues;
    }

    const masterContainerStyle = {
        ...getContainerStyle("1f2172f8-1577-4e22-b758-d3be0ee44a9e", template, cards, card, variables, templateData, null),
        paddingTop: "4px",
        paddingBottom: "4px",
        paddingLeft: "7px",
        paddingRight: "7px"
    }

    const childContainerStyle = {
        ...getContainerStyle("ff122b43-010b-480a-a216-71eef6d543d0", template, cards, card, variables, templateData, null),
        padding: '1px',
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3" style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null }}>
            <div className='flex items-center'>
                <VariableValueExtractor variableId={"803032c3-28c5-4932-ba92-983b86f2111b"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
                <div className="child-container flex mx-1" style={{ ...childContainerStyle, flex: "0 0 auto", textDecorationLine: "none" }}>
                    <VariableValueExtractor variableId={"90bfa3b5-3e5e-403a-9751-157080c6448b"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                    <VariableValueExtractor variableId={"0126a237-d33f-4788-b77c-0a9e8b3b71aa"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </div>
            </div>
            <div>
                {findModuleVariables("e02168bf-4e8c-4116-bde5-20cd1e9bd0f4", variables).map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={card}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            fullWidth={true}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default IfYouHaveDisciplineSandbox;