import './_card.scss';

import { useEffect, useState } from 'react';
import { useParams, useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { isMobile } from 'react-device-detect';

import ChapterManager from '../../managers/chapter-manager';
import hiddenCardsData from '../../_data/hidden-cards.json';
import { orderCards } from '../..//utils/libs';
import { AnimatePresence, motion } from "framer-motion";
import { ReactComponent as BarsIcon } from '../../components/icons/DefaultIconSvgs/Menu.svg'

import Loading from '../../components/Loading';
import { ContentTableMenu, ContentTablePage } from './content-table';
import EventLeft from '../../components/templates/EventLeft';
import CoverPrologueFront from '../../components/templates/CoverPrologueFront';
import TableOfContents from '../../components/templates/TableOfContents';
import SectionEvent from '../../components/templates/SectionEvent';
import EventEndLeft from '../../components/templates/EventEndLeft';
import FullImagePage from '../../components/templates/FullImagePage';
import CoverPrologueBack from '../../components/templates/CoverPrologueBack';
import SectionDialogue from '../../components/templates/SectionDialogue';
import DialogueNPCDivider from '../../components/templates/DialogueNPCDivider';
import DialogueLeft from '../../components/templates/DialogueLeft';
import DialogueEndLeft from '../../components/templates/DialogueEndLeft';
import SectionInvestigation from '../../components/templates/SectionInvestigation';
import InvestigationFirstPage from '../../components/templates/InvestigationFirstPage';
import InvestigationLeft from '../../components/templates/InvestigationLeft';
import GuessLeft from '../../components/templates/GuessLeft';
import SectionFinalDeduction from '../../components/templates/SectionFinalDeduction';
import FinalDeductionFirstPage from '../../components/templates/FinalDeductionFirstPage';
import FinalDeductionLeft from '../../components/templates/FinalDeductionLeft';
import FinalDeductionEndLeft from '../../components/templates/FinalDeductionEndLeft';
import EventFailLeft from '../../components/templates/EventFailLeft';
import EventActionTokenEndLeft from '../../components/templates/EventActionTokenEndLeft';
import EventActionTokenLeft from '../../components/templates/EventActionTokenLeft';
import PrologueIntroductionNpcColumnCombat from '../../components/templates/PrologueIntroductionNpcColumnCombat';
import MainMenu from '../../components/utils/MainMenu';
import PrologueIntroductionNoNpcColumnSpecial from '../../components/templates/PrologueIntroductionNoNpcColumnSpecial';
import PrologueIntroNoTileSpecial from '../../components/templates/PrologueIntroNoTileSpecial';
import PrologueIntroductionNpcColumn from '../../components/templates/PrologueIntroductionNpcColumn';
import PrologueConclusion from '../../components/templates/PrologueConclusion';
import PrologueIntroductionNpcColumnSpecial from '../../components/templates/PrologueIntroductionNpcColumnSpecial';
import CoverFront from '../../components/templates/CoverFront';
import EventSpecialChapter from '../../components/templates/EventSpecialChapter';
import CoverBack from '../../components/templates/CoverBack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import DialogueLeftTight from '../../components/templates/DialogueLeftTight';
import EventEndLeftWithBottom from '../../components/templates/EventEndLeftWithBottom';
import DialogueFailLeft from '../../components/templates/DialogueFailLeft';
import EventLeftTight from '../../components/templates/EventLeftTight';
import EventSetupWithNoBottomSpeciaChapterLeft from '../../components/templates/EventSetupWithNoBottomSpeciaChapterLeft';
import EventActionEndWithBottomLeft from '../../components/templates/EventActionEndWithBottomLeft';
import InvestigationFail from '../../components/templates/InvestigationFail';
import InvestigationLeftTight from '../../components/templates/InvestigationLeftTight';
import InvestigationLeftEnd from "../../components/templates/InvestigationLeftEnd";
import ChapterIntro1NpcColumn from "../../components/templates/ChapterIntro1NpcColumn";
import ChapterConclusion from "../../components/templates/ChapterConclusion";
import ChapterIntro0NPCColumnToreadorFlaw from "../../components/templates/ChapterIntro0NPCColumnToreadorFlaw";
import ChapterIntro1NpcColumnToreadorFlaw from "../../components/templates/ChapterIntro1NpcColumnToreadorFlaw";
import ChapterIntro1NpcColumnSpecialText from "../../components/templates/ChapterIntro1NpcColumnSpecialText";
import ChapterIntro2NPCColumns from "../../components/templates/ChapterIntro2NPCColumns";
import ChapterIntro0NpcColumn from "../../components/templates/ChapterIntro0NpcColumn";
import ChapterIntro2NpcColumnToreadorFlaw from "../../components/templates/ChapterIntro2NpcColumnToreadorFlaw";
import ChapterIntro1NpcColumnPatrol from "../../components/templates/ChapterIntro1NpcColumnPatrol";
import ChapterIntro0NpcColumnSpecial from "../../components/templates/ChapterIntro0NpcColumnSpecial";
import ChapterIntro1NPCColumnToreadorFlawSpecial from "../../components/templates/ChapterIntro1NPCColumnToreadorFlawSpecial";
import ChapterIntro2NPCColumnSpecialText from '../../components/templates/ChapterIntro2NPCColumnSpecialText';
import ChapterIntro0NpcColumnSmallerTitle from '../../components/templates/ChapterIntro0NpcColumnSmallerTitle';
import SidequestIntro1NPCColumnToreadorFlaw from '../../components/templates/SidequestIntro1NPCColumnToreadorFlaw';
import SidequestIntro0NPCColumnSOLO from '../../components/templates/SidequestIntro0NPCColumnSOLO';
import SidequestConclusion from '../../components/templates/SidequestConclusion';
import InterludeFrontPage from '../../components/templates/InterludeFrontPage';
import InterludePageLeft from '../../components/templates/InterludePageLeft';
import InterludePageRight from '../../components/templates/InterludePageRight';
import InterludeEndPageRight from '../../components/templates/InterludeEndPageRight';
import InterludeSectionPageLeft from '../../components/templates/InterludeSectionPageLeft';
import ExpansionPackIntro1NPCColumn from '../../components/templates/ExpansionPackIntro1NPCColumn';
import ExpansionPackConclusionNoReward from '../../components/templates/ExpansionPackConclusionNoReward';
import ExpansionPackIntroNPCColumnSpecialInstruction from '../../components/templates/ExpansionPackIntroNPCColumnSpecialInstruction';
import ExpansionPackConclusion from '../../components/templates/ExpansionPackConclusion';
import ExpansionPackIntro2NPCColumns from '../../components/templates/ExpansionPackIntro2NPCColumns';
import ExpansionPackintro1NPCColumnRegularTile from '../../components/templates/ExpansionPackintro1NPCColumnRegularTile';
import ExpansionPackIntro1NPCColumnPatrolSpecialInstruction from '../../components/templates/ExpansionPackIntro1NPCColumnPatrolSpecialInstruction';
import ExpansionPackIntro0NPCColumnSpecialInstruction from '../../components/templates/ExpansionPackIntro0NPCColumnSpecialInstruction';
import SidequestIntro1NPCColumn from '../../components/templates/SidequestIntro1NPCColumn';
import SidequestConclusionNoReward from '../../components/templates/SidequestConclusionNoReward';
import ChapterIntro2or3NPCColumnsToreadorFlaw from '../../components/templates/ChapterIntro2or3NPCColumnsToreadorFlaw';
import XXXChapterIntro3NPCColumns from '../../components/templates/XXXChapterIntro3NPCColumns';
import ChapterIntroNoSetup from '../../components/templates/ChapterIntroNoSetup';
import ChapterIntro0NPCColumnMultipleFirstActions from '../../components/templates/ChapterIntro0NPCColumnMultipleFirstActions';
import ChapterIntro0NPCColumnVerticalTile from '../../components/templates/ChapterIntro0NPCColumnVerticalTile';
import InterludeModularBottomSectionsPageLeft from '../../components/templates/InterludeModularBottomSectionsPageLeft';
import InterludeEndSectionPageRight from '../../components/templates/InterludeEndSectionPageRight';
import XxxExpansionPackIntro1NpcColumnSoloSpecialInstruction from '../../components/templates/XxxExpansionPackIntro1NpcColumnSoloSpecialInstruction';
import XxxExpansionPackIntro3NpcColumnsSoloSpecialInstruction from '../../components/templates/XxxExpansionPackIntro3NpcColumnsSoloSpecialInstruction';
import XxxExpansionPackIntro1NpcColumnPatrolSpecialInstructionSolo from '../../components/templates/XxxExpansionPackIntro1NpcColumnPatrolSpecialInstructionSolo';
import XxxExpansionPackIntro0NpcColumnSpecialInstructionSolo from '../../components/templates/XxxExpansionPackIntro0NpcColumnSpecialInstructionSolo';
import XxxSideQuestIntro1NpcColumnCombatSpecial from '../../components/templates/XxxSideQuestIntro1NpcColumnCombatSpecial';
import SidequestIntro0NpcColumn from '../../components/templates/SidequestIntro0NpcColumn';
import InvestigationLeftEndWithBottom from '../../components/templates/InvestigationLeftEndWithBottom';
import ChapterConclusionEnding from '../../components/templates/ChapterConclusionEnding';

export default function Card() {
    const location = useLocation();
    const navigate = useNavigate();
    const { chapterId, cardId } = useParams();
    const [params, setSearchParams] = useSearchParams();
    const [showContentTablePage, setShowContentTablePage] = useState(false);
    const [chapter, setChapter] = useState(null);
    const [chapters, setChapters] = useState([]);
    const [templateData, setTemplateData] = useState(null);
    const [currentPage, setCurrentPage] = useState(null);
    const [tableOfContentCard, setTableOfContentCard] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const [eventPage, setEventPage] = useState(null);

    const [loading, setLoading] = useState(true);
    const [hiddenCards, setHiddenCards] = useState(hiddenCardsData.find(_ => _.id === chapterId));
    const [cardIndex, setCardIndex] = useState(null);
    const [currentCard, setCurrentCard] = useState(null);
    const [frontTemplate, setFrontTemplate] = useState(null);

    const direction = params.get('direction');
    const pathname = location.pathname;

    const getCardIndex = (id) => {
        if (!chapter) return;
        return chapter.cardsOrder.findIndex(_ => _._id === id);
    }

    const findCard = (id) => {
        if (!chapter) return;
        return chapter.cards?.find(_ => _._id === id);
    }

    useEffect(() => {
        if (!templateData || !cardId || !chapter) return;

        let card = findCard(cardId);
        if (!card) return;

        setCardIndex(getCardIndex(cardId));
        setCurrentCard(card);
        setFrontTemplate(templateData.currentVariants.find(_ => _._id === card.frontVariant));

        let chapterUtils = chapters.find(_ => _.id === chapterId);
        if (chapterUtils) {
            setTableOfContentCard(findCard(chapterUtils.tableOfContent));
            setEventPage(chapterUtils.eventPage);
        }

        setHiddenCards(hiddenCardsData.find(_ => _.id === chapterId));

        setLoading(false);
        
    }, [cardId, chapter, templateData, chapters, chapterId]);

    useEffect(() => {
        if (!currentPage) return;

        navigate(`/${chapterId}/${currentPage}`);
        setCurrentPage(null);
    }, [currentPage]);

    useEffect(() => {
        console.log(frontTemplate?.name);
    }, [frontTemplate]);

    useEffect(() => {
        ChapterManager.loadChaptersAsync((data) => {
            setChapters(data);
        });

        ChapterManager.loadChapterDataAsync(chapterId, (data) => {
            // data.cardsOrder = removeUnwantedCards(data.cardsOrder);
            data.cards = orderCards(data.cards, data.cardsOrder);
            setChapter(data);
        });

        ChapterManager.loadTemplateDataAsync((data) => {
            setTemplateData(data);
        })
    }, [])

    useEffect(() => {
        ChapterManager.loadChapterDataAsync(chapterId, (data) => {
            // data.cardsOrder = removeUnwantedCards(data.cardsOrder);
            data.cards = orderCards(data.cards, data.cardsOrder);
            setChapter(data);
        });
    }, [chapterId]);

    const removeUnwantedCards = (cardsOrder) => {
        const hidden = hiddenCardsData.find(_ => _.id === chapterId);

        let finalOrder = [];
        cardsOrder.forEach(element => {
            if(!hidden.hiddens.find(_=>_.id === element._id)){
                finalOrder.push(element);
            }
        });

        return finalOrder;
    }

    const handleGoNext = () => {
        if (cardIndex >= chapter.cardsOrder.length - 1) return;

        let finalIndex = cardIndex;
        let newCard = null;
        let cardHidden = null;

       
        do {
            finalIndex = finalIndex + 1;
            if (cardIndex >= chapter.cardsOrder.length - 1) return;

            if(!chapter.cardsOrder[finalIndex]) return;

            newCard = chapter.cards.find(_=>_._id === chapter.cardsOrder[finalIndex]._id);

            if (newCard) {
                cardHidden = hiddenCards?.hiddens?.find(_ => _.id === newCard._id);
            }
        } while (!newCard || newCard?.hideWebApp || cardHidden || newCard?.name?.toLowerCase().indexOf('separator') > -1);

        if (newCard) {
            navigate(`/${chapterId}/${newCard._id}`);
        }

    }

    const handleGoBack = () => {
        if (cardIndex === 0) return;

        let finalIndex = cardIndex;
        let newCard = null;
        let cardHidden = null;
        do {
            finalIndex = finalIndex - 1;
            if (finalIndex < 0) return;

            newCard = chapter.cards.find(_=>_._id === chapter.cardsOrder[finalIndex]._id);

            if (newCard) {
                cardHidden = hiddenCards?.hiddens?.find(_ => _.id === newCard._id);
            }

        } while (!newCard || newCard.hideWebApp || cardHidden || newCard.name?.toLowerCase().indexOf('separator') > -1);

        if (newCard) {
            navigate(`/${chapterId}/${newCard._id}?direction=1`);
        }
    }

    const handlers = useSwipeable({
        onSwipedRight: (eventData) => {
            handleGoBack()
        },
        onSwipedLeft: (data) => {
            handleGoNext()
        }
    });

    const handleMenuClick = () => {
        if (menuOpen === null) {
            setMenuOpen(true);
            return;
        }
        setMenuOpen(!menuOpen);
    }

    const getBarMenuColor = (name) => {
        let whiteNames = ["Section Event", "Cover Prologue BACK", "Section Dialogue", "Dialogue NPC Divider" , "Cover Prologue FRONT", 
        "Section Investigation", "Investigation First Page", "Section Final Deduction", "Final Deduction First Page", "Prologue Conclusion", 
        "Event Special Chapter 1","Cover Back", "Cover Front", "Full Image Page" , "Chapter Conclusion", "Sidequest Conclusion", "Expansion Pack Conclusion No Reward",
        "Expansion Pack Conclusion","Sidequest Conclusion No Reward", "Chapter Conclusion Ending"
        ]
        if(whiteNames.indexOf(name) > -1) return "white";

        return "black";
    }

    return (<>
        {loading ? <Loading /> :
            <div className='card-wrapper relative'>
                <div className={isMobile ? 'navigation absolute left-0' : 'navigation show'}
                    style={isMobile ? { width: "30px" } : { width: "20px", backgroundColor: "white" }} onClick={() => { handleGoBack() }}>
                    {!isMobile && (<FontAwesomeIcon icon={faCaretLeft} />)}
                </div>

                <div className='chapter-page overflow-x-hidden '>


                    {currentCard && (
                        <AnimatePresence>
                            <motion.div className='chapter-container' {...handlers} key={pathname} name={pathname}
                                initial={{ x: direction ? "-100%" : '100%', position: "fixed" }}
                                animate={{ x: '0', position: 'relative' }}
                                exit={{ opacity: 0, position: "fixed" }}
                                transition={{
                                    x: { type: "spring", bounce: 0 },
                                    opacity: { duration: 0.2 }
                                }}
                            >
                                {!menuOpen &&
                                    (<div className='open-close-container'>
                                        <a className='open-close-button' href="#1" onClick={() => handleMenuClick()}>
                                            <BarsIcon width={50} height={50} fill={getBarMenuColor(frontTemplate?.name)} />
                                        </a>
                                    </div>)}

                                {frontTemplate?.name === "Cover Prologue FRONT" && (<CoverPrologueFront currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} />)}
                                {frontTemplate?.name === "Table Of Contents" && (<TableOfContents currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} />)}
                                {frontTemplate?.name === "Section Event" && (<SectionEvent currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} />)}
                                {frontTemplate?.name === "Event LEFT" && (<EventLeft currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} />)}
                                {frontTemplate?.name === "Event End LEFT" && (<EventEndLeft currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} />)}
                                {frontTemplate?.name === "Event Action Token LEFT" && (<EventActionTokenLeft currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} />)}
                                {frontTemplate?.name === "Event Action Token End LEFT" && (<EventActionTokenEndLeft currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} />)}
                                {frontTemplate?.name === "Event Fail LEFT" && (<EventFailLeft currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} />)}
                                {frontTemplate?.name === "Full Image Page" && (<FullImagePage currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} />)}
                                {frontTemplate?.name === "Cover Prologue BACK" && (<CoverPrologueBack currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} />)}
                                {frontTemplate?.name === "Section Dialogue" && (<SectionDialogue currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} />)}
                                {frontTemplate?.name === "Dialogue NPC Divider" && (<DialogueNPCDivider currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} />)}
                                {frontTemplate?.name === "Dialogue LEFT" && (<DialogueLeft currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} />)}
                                {frontTemplate?.name === "Dialogue End LEFT" && (<DialogueEndLeft currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} />)}
                                {frontTemplate?.name === "Section Investigation" && (<SectionInvestigation currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} />)}
                                {frontTemplate?.name === "Investigation First Page" && (<InvestigationFirstPage currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} />)}
                                {frontTemplate?.name === "Investigation LEFT" && (<InvestigationLeft currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} />)}
                                {frontTemplate?.name === "Guess LEFT" && (<GuessLeft currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} />)}
                                {frontTemplate?.name === "Section Final Deduction" && (<SectionFinalDeduction currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} />)}
                                {frontTemplate?.name === "Final Deduction First Page" && (<FinalDeductionFirstPage currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} />)}
                                {frontTemplate?.name === "Final Deduction LEFT" && (<FinalDeductionLeft currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} />)}
                                {frontTemplate?.name === "Final Deduction End LEFT" && (<FinalDeductionEndLeft currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} />)}
                                {frontTemplate?.name === "Prologue Conclusion" && (<PrologueConclusion currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} />)}
                                {frontTemplate?.name === "Prologue Intro 1 NPC Column Combat Special" && (<PrologueIntroductionNpcColumnCombat currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Prologue Intro 1 NPC Column Special" && (<PrologueIntroductionNpcColumnSpecial currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Prologue Intro 0 NPC Column Special" && (<PrologueIntroductionNoNpcColumnSpecial currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Prologue Intro No Tile Special" && (<PrologueIntroNoTileSpecial currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Prologue Intro 1 NPC Column" && (<PrologueIntroductionNpcColumn currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Cover Front" && (<CoverFront currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Event Special Chapter 1" && (<EventSpecialChapter currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Cover Back" && (<CoverBack currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Dialogue LEFT TIGHT" && (<DialogueLeftTight currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Event End Left With Bottom" && (<EventEndLeftWithBottom currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Dialogue Fail LEFT" && (<DialogueFailLeft currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Event LEFT TIGHT" && (<EventLeftTight currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Event Setup With No Bottom (Special Chapter 12) LEFT " && (<EventSetupWithNoBottomSpeciaChapterLeft currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Event Action End With Bottom Left" && (<EventActionEndWithBottomLeft currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Investigation Fail" && (<InvestigationFail currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Investigation LEFT TIGHT" && (<InvestigationLeftTight currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Investigation LEFT End" && (<InvestigationLeftEnd currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Chapter Intro 1 NPC Column" && (<ChapterIntro1NpcColumn currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Chapter Conclusion" && (<ChapterConclusion currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Chapter Intro 0 NPC Column Toreador Flaw" && (<ChapterIntro0NPCColumnToreadorFlaw currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Chapter Intro 1 NPC Column Toreador Flaw" && (<ChapterIntro1NpcColumnToreadorFlaw currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Chapter Intro 1 NPC Column Special Text" && (< ChapterIntro1NpcColumnSpecialText currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Chapter Intro 2 NPC Columns" && (< ChapterIntro2NPCColumns currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Chapter Intro 0 NPC Column" && (< ChapterIntro0NpcColumn currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Chapter Intro 0 NPC Column (smaller title)" && (< ChapterIntro0NpcColumnSmallerTitle currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Chapter Intro 2 NPC Column Toreador Flaw" && (< ChapterIntro2NpcColumnToreadorFlaw currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Chapter Intro 1 NPC Column Patrol" && (< ChapterIntro1NpcColumnPatrol currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Chapter Intro 0 NPC Column Special" && (< ChapterIntro0NpcColumnSpecial currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Chapter Intro 1 NPC Column Toreador Flaw Special" && (< ChapterIntro1NPCColumnToreadorFlawSpecial currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Chapter Intro 2 NPC Column Special Text" && (< ChapterIntro2NPCColumnSpecialText currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "XXX Sidequest Intro 1 NPC Column Toreador Flaw" && (< SidequestIntro1NPCColumnToreadorFlaw currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "XXX Sidequest Intro 0 NPC Column (SOLO)" && (<SidequestIntro0NPCColumnSOLO currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Sidequest Conclusion" && (<SidequestConclusion currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Interlude Front Page" && (<InterludeFrontPage currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Interlude Page LEFT" && (<InterludePageLeft currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Interlude Page RIGHT" && (<InterludePageRight currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Interlude End Page RIGHT" && (<InterludeEndPageRight currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Interlude Section Page LEFT" && (<InterludeSectionPageLeft currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Expansion Pack Intro 1 NPC Column" && (<ExpansionPackIntro1NPCColumn currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Expansion Pack Conclusion No Reward" && (<ExpansionPackConclusionNoReward currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Expansion Pack Intro 1 NPC Column Special Instruction" && (< ExpansionPackIntroNPCColumnSpecialInstruction currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Expansion Pack Conclusion" && (< ExpansionPackConclusion currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Expansion Pack Intro 2 NPC Columns" && (< ExpansionPackIntro2NPCColumns currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Expansion Pack Intro 1 NPC Column Regular Tile" && (< ExpansionPackintro1NPCColumnRegularTile currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Expansion Pack Intro 1 NPC Column Patrol Special Instruction" && (< ExpansionPackIntro1NPCColumnPatrolSpecialInstruction currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Expansion Pack Intro 0 NPC Column Special Instruction" && (<  ExpansionPackIntro0NPCColumnSpecialInstruction currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Sidequest Intro 1 NPC Column" && (<SidequestIntro1NPCColumn currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Sidequest Conclusion No Reward" && (<SidequestConclusionNoReward currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Chapter Intro 2 or 3 NPC Columns Toreador Flaw" && (<ChapterIntro2or3NPCColumnsToreadorFlaw currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "XXX Chapter Intro 3 NPC Columns" && (<XXXChapterIntro3NPCColumns currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Chapter Intro No Setup" && (<ChapterIntroNoSetup currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Chapter Intro 0 NPC Column Multiple First Actions" && (<ChapterIntro0NPCColumnMultipleFirstActions currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Chapter Intro 0 NPC Column Vertical Tile" && (<ChapterIntro0NPCColumnVerticalTile currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Interlude Modular Bottom Sections Page LEFT" && (<InterludeModularBottomSectionsPageLeft currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Interlude End Section Page RIGHT" && (<InterludeEndSectionPageRight currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "XXX Expansion Pack Intro 1 NPC Column SOLO SPECIAL INSTRUCTION" && (<XxxExpansionPackIntro1NpcColumnSoloSpecialInstruction currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "XXX Expansion Pack Intro 3 NPC Columns SOLO SPECIAL INSTRUCTION" && (<XxxExpansionPackIntro3NpcColumnsSoloSpecialInstruction currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "XXX Expansion Pack Intro 1 NPC Column Patrol Special Instruction SOLO" && (<XxxExpansionPackIntro1NpcColumnPatrolSpecialInstructionSolo currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "XXX Expansion Pack Intro 0 NPC Column Special Instruction SOLO" && (<XxxExpansionPackIntro0NpcColumnSpecialInstructionSolo currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "XXX Side Quest Intro 1 NPC Column Combat Special" && (<XxxSideQuestIntro1NpcColumnCombatSpecial currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}                                
                                {frontTemplate?.name === "Sidequest Intro 0 NPC Column" && (<SidequestIntro0NpcColumn currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Investigation LEFT END WITH BOTTOM" && (<InvestigationLeftEndWithBottom currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}
                                {frontTemplate?.name === "Chapter Conclusion Ending" && (<ChapterConclusionEnding currentCard={currentCard} template={frontTemplate} cards={chapter.cards} deckId={chapterId} templateData={templateData} eventPage={eventPage} />)}

                            </motion.div>
                        </AnimatePresence>
                    )}

                    {tableOfContentCard && (
                        <div key="2" className='content-table-menu'>
                            <ContentTableMenu setShowContentTablePage={setShowContentTablePage} />
                        </div>)
                    }
                    
                </div>

                <div className={isMobile ? 'navigation absolute right-0' : 'navigation show'}
                    style={isMobile ? { width: "30px" } : { width: "20px", backgroundColor: "white" }} onClick={() => { handleGoNext() }}>
                    {!isMobile && (<FontAwesomeIcon icon={faCaretRight} />)}
                </div>
            </div>
        }

            <AnimatePresence>
                
                {showContentTablePage && (
                    <motion.div key={"content-table-menu"} className='absolute w-full h-full' style={{top:0}}
                    initial={{ y: "100%", position:"absolute"}}
                    animate={{ y: "0%", position:"absolute" }}
                    exit={{ y: "100%", position:"absolute" }}
                    transition={{
                        x: { type: "spring", bounce: 0 },
                        opacity: { duration: 0.2 }
                    }}
                    >
                        <ContentTablePage card={tableOfContentCard} templateData={templateData} cardsOrder={chapter.cardsOrder}
                        cards={chapter.cards} deckId={chapterId} setShowContentTablePage={setShowContentTablePage} />
                    
                    </motion.div>
                )}
            </AnimatePresence>

        <MainMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen} chapters={chapters}></MainMenu>
    </>

    )
}