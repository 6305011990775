import { CONTENT_WIDTH, DEFAULT_ORANGE_CODE } from '../../utils/constants';
import { findModuleVariables } from '../../utils/variable-extractor';
import VariableValueExtractor from '../utils/VariableValueExtractor';
import ModuleSelector from './_module-selector';
const TemplateInterludeSubSection = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId,
    currentCard
}) => {

    if(variables.variablesValues){
        variables = variables.variablesValues;
    }
    
    
    return (
        <div name={`${template._id} - ${template.name}`} className="my-1" style={{width: !fullWidth ? CONTENT_WIDTH : null}}>

            <div className='flex items-center justify-center' style={{color:DEFAULT_ORANGE_CODE}}>
                <div className='mr-3' style={{fontWeight:"bold"}}>-----</div>
                    <VariableValueExtractor 
                        variableId={"5900c64c-36a3-47f1-9819-6567ed13c5a5"} 
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        ctaId={ctaId}
                        parentId={parentId}
                        renderId={"795fa2c4-c439-4414-854c-d2bbb2dd0e4a"}
                        />
                <div className='ml-3' style={{fontWeight:"bold"}}>-----</div>
            </div>

            <VariableValueExtractor 
                variableId={"b56e1750-e75b-4966-a6e2-4cd222143b37"} 
                template={template}
                cards={cards}
                card={card}
                variables={variables}
                templateData={templateData}
                ctaId={ctaId}
                parentId={parentId}
                renderId={"e7ec369a-170e-4f52-929f-8d0a683f153e"}
                />

            <div className='custom-font-size mb-10 w-full'>
                {findModuleVariables( "53ab946d-ccfc-4865-b4ab-0055e4d4735a", variables).map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={card}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            separatorColor={'black'}
                            noStyle={true}
                        />
                    )
                })}

                {findModuleVariables("5019823c-278e-4bc1-bebc-2c5de5a27674", variables).map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={card}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            separatorColor={'black'}
                        />
                    )
                })}

            </div>
        </div>
    )
}

export default TemplateInterludeSubSection;