import './_templates.scss'
import { findVariableOrDefaultToTempateValue, findModuleVariables, getContainerStyle } from '../../utils/variable-extractor';
import ModuleSelector from '../modules/_module-selector';
import { CONTENT_WIDTH, DEFAULT_ORANGE_CODE,  BLOB_URL, V_NUMBER } from '../../utils/constants';
import { FONT_SIZE_MP } from '../../utils/libs';
import { Link } from 'react-router-dom';
import HexGrid from '../tools/HexGrid';
import VariableValueExtractor from "../utils/VariableValueExtractor";


const ChapterIntro0NPCColumnToreadorFlaw = ({
    currentCard,
    template,
    cards,
    deckId,
    templateData,
    eventPage,
    ctaId,
}) => {

   const parentId = currentCard._id;
 
   const masterContainerStyle = {
        ...getContainerStyle("309721ec-23a3-45f3-bf7c-5eb07dd37ca8", template, cards, currentCard, currentCard.variablesValues, templateData, null)
    }

    return (
        <div className="w-full h-full flex flex-col overflow-y-auto" style={masterContainerStyle}>
            <div className='w-full text-center' style={{ marginTop: "50px" }}>
                <h5 style={{ fontFamily: "CrimsonPro", color: DEFAULT_ORANGE_CODE, fontWeight: "bold", fontSize: `${8 * FONT_SIZE_MP}px` }}>
                    <VariableValueExtractor variableId={"22c19678-71f0-48e7-ab79-f63781c10870"}
                        template={template}
                        cards={cards}
                        card={currentCard}
                        variables={currentCard.variablesValues}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </h5>
                <h1 style={{ fontFamily: "Oswald-Bold", color: "Black", fontWeight: "bold", fontSize: `${20 * FONT_SIZE_MP}px` }}>
                    INTRODUCTION
                </h1>
            </div>

            <div className='force-font-size flex flex-col items-center mt-3'
                style={{ fontFamily: "CrimsoPro", color: "black" }}>
                {findModuleVariables("4ca9105a-fe49-466d-8748-e0885a8b75eb", currentCard).map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={currentCard}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            noStyle={true}
                        />
                    )
                })}

                {findModuleVariables("097cbf93-f94a-4978-9da6-902dfed35639", currentCard).map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={currentCard}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            noStyle={true}
                        />
                    )
                })}
            </div>

            <div className='flex justify-center my-5'>
                <div style={{
                    width: CONTENT_WIDTH,
                    ...getContainerStyle("eaa5bb28-2d02-4a6d-8848-c3080f53e327", template, cards, currentCard, currentCard.variablesValues, templateData),
                    padding: '15px'
                }}>
                    <h3 style={{ color: DEFAULT_ORANGE_CODE, fontFamily: "CrimsonPro" }}>
                        OBJECTIVE
                    </h3>

                    <div style={{textTransform: "uppercase", fontWeight: "400", fontFamily: "Oswald-Regular", color: "rgb(241, 228, 215)", fontSize: "25px"}}>
                        <VariableValueExtractor variableId={"3b0596d3-eb6f-47fe-a021-f1b9d16402e7"}
                            template={template}
                            cards={cards}
                            card={currentCard}
                            variables={currentCard.variablesValues}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>

                    <div className='line my-4'></div>

                    <h3 style={{ color: DEFAULT_ORANGE_CODE, fontFamily: "CrimsonPro", }}>
                        ACTION
                    </h3>
                    <div className={currentCard._id === "6560e6a6e22486beedf2679c" ? "customChapToreadorFlaw" : ""} style={{textTransform: "uppercase", fontWeight: "400", fontFamily: "Oswald-Regular", color: "rgb(241, 228, 215)", fontSize: "25px"}}>
                        <Link to={`/${deckId}/${eventPage}`}>
                            <VariableValueExtractor variableId={"0fcc37f2-6788-41ed-a982-aa3903e08f35"}
                                template={template}
                                cards={cards}
                                card={currentCard}
                                variables={currentCard.variablesValues}
                                templateData={templateData}
                                renderId={"1360f240-4ec2-4bc0-a9dc-f0a170711269"}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                        </Link>
                        {currentCard._id === "6560e6a6e22486beedf2679c" && (
                        <div>                
                            OTHERWISE,&nbsp;
                            <Link to={`/65572cbb58c548552e3df6d3/6557354158c548552e3e769b`} style={{color: DEFAULT_ORANGE_CODE, fontFamily:"Oswald-SemiBold", textDecoration:"underline"}}>
                                READ EVENT E.2
                            </Link>
                        </div>
                        )}
                    </div>

                    <div className='line my-4'></div>

                    <div className='flex justify-between'>
                        <div style={{textTransform: "uppercase", fontWeight: "400", fontFamily: "Oswald-Regular", color: "rgb(241, 228, 215)", fontSize: "25px"}}>
                            <VariableValueExtractor variableId={"17686dec-e162-463f-b77f-f85c5f93511b"}
                                template={template}
                                cards={cards}
                                card={currentCard}
                                variables={currentCard.variablesValues}
                                templateData={templateData}
                                renderId={"1360f240-4ec2-4bc0-a9dc-f0a170711269"}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                        </div>
                        <div>
                             <img alt="icon" width='40px' src={BLOB_URL + findVariableOrDefaultToTempateValue("66ba8944-ef4f-4bd4-b25e-f98b7d596b7b", template, cards, templateData, null).image + "?v=" + V_NUMBER}></img>
                        </div>
                    </div>
                    
                    <div>

                    </div>
                </div>
            </div>

            <div className='flex flex-col justify-center items-center my-5'>
                <div style={{ width: '175px' }}>
                    <div className='line my-2' style={{ height: '2px' }}></div>
                    <h3 className='text-center' style={{ fontFamily: "Oswald-Regular", fontSize: '25px' }}>INITIAL TILE <span className='orange-text ftOswaldSb'>SETUP</span></h3>
                    <div className='line my-2' style={{ height: '2px' }}></div>
                </div>
            </div>

            <div className='flex justify-center mb-5 relative'>
                <HexGrid
                    variables={currentCard.variablesValues}
                    template={template}
                    cards={cards}
                    card={currentCard}
                    templateData={templateData}
                    variable="633b2f6d-3791-47f3-80d8-76f91974c795"
                />
                <div className='absolute bottom-2 right-6' style={{ color: DEFAULT_ORANGE_CODE, fontFamily: "Oswald-SemiBold", fontSize: '22.5px'}}>
                    <VariableValueExtractor variableId={"84333462-ceed-484f-820b-b92f63636b43"}
                        template={template}
                        cards={cards}
                        card={currentCard}
                        variables={currentCard.variablesValues}
                        templateData={templateData}
                        renderId={"c9ebb909-29f9-4f59-8148-22ea935c9f3b"}
                        isLink={null}
                    />
                </div>
            </div>    
        </div>
    )
}

export default ChapterIntro0NPCColumnToreadorFlaw;