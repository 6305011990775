import { getContainerStyle } from '../../utils/variable-extractor';
import { ReactComponent as XIcon } from '../icons/DefaultIconSvgs/Warning_symbol.svg'
import VariableValueExtractor from "../utils/VariableValueExtractor";


const XxxStorybookConclusionNoDiscardClueTokens = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    // if (variables.variablesValues) {
    //     variables = variables.variablesValues;
    // }
    const masterContainerStyle = {
        ...getContainerStyle("94a6f248-eabc-4724-9f1d-238d484718bd", template, cards, card, variables, templateData, null),
        padding: "7px"
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3 flex flex-col full-w" style={{ ...masterContainerStyle}}>
            <div className='flex justify-center items-center'>
                 <XIcon height={20} width={20} fill="white"/>
                <div className='lineGreyCustom ml-1'>
                    <VariableValueExtractor variableId={"4b86e152-0b65-44b2-aaaf-e989b5c018c1"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </div>
            </div>
            <div>
                <VariableValueExtractor variableId={"de7a30e6-b0c9-4b02-97e6-510c5aa329d8"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
            </div>          
        </div>
    )
}

export default XxxStorybookConclusionNoDiscardClueTokens;