import { BLOB_URL, CONTENT_WIDTH } from '../../utils/constants';
import { getContainerStyle, findNestedModuleVariables, findNestedVariable, getRenderColor } from '../../utils/variable-extractor';
import ModuleSelector from './_module-selector';
import ClanIcons from '../icons/ClanIcons';
import VariableValueExtractor from "../utils/VariableValueExtractor";


const StorybookIntroTextCalebLetter = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    
    
    if (variables && variables.variablesValues) {
        variables = variables.variablesValues;
    }

    let containerRender = template?.render[0];
    let backgroundImage =  BLOB_URL + 'upload/images/64ed011e17b7622c4d323360/Storybook/Special/Caleb_Letter.webp';

    const masterContainerStyle = {
        ...getContainerStyle(containerRender?.uuid, template, cards, card, variables, templateData, null),
        paddingTop: "30px",
        paddingBottom: "4px",
        paddingLeft: "7px",
        paddingRight: "7px",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'right center', // Centrer l'image de fond pour qu'elle soit bien positionnée
        backgroundRepeat: 'no-repeat',
        height: "350px"
    }


    const ctaVariableId = template.render[0]?.children[1]?.variable;

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3 flex " style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null }}>
            <div className='grow p-10'>
                <VariableValueExtractor variableId={ctaVariableId}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={"74523533-c7f7-4042-a8a8-918c2c4eb113"}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/> 
            </div>
        </div>
    )
}

export default StorybookIntroTextCalebLetter;