import { CONTENT_WIDTH } from '../../utils/constants';
import { getContainerStyle, findModuleVariables } from '../../utils/variable-extractor';
import ChaptersIcons from '../icons/ChaptersIcons';
import ModuleSelector from "./_module-selector";
import VariableValueExtractor from "../utils/VariableValueExtractor";


const YouMustEachMakeAttributeSkillCheck = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {
  
    if (variables.variablesValues) {
        variables = variables.variablesValues;
    }

    let mainContainer = template?.render[0];
    let mainText = mainContainer?.children[0]?.children[0]?.uuid;
    let childContainer = mainContainer?.children[0]?.children[1];
    let childText = childContainer?.children[0]?.uuid;
    let childGreyContainer = mainContainer?.children[1];
     
    let successText = mainContainer?.children[2]?.children[0]?.children[1]?.uuid;
    let sucessModule = mainContainer?.children[2]?.children[1]?.children[0]?.variable;
    let faillureText = mainContainer?.children[2]?.children[3]?.children[1]?.uuid;
    let faillureModule = mainContainer?.children[2]?.children[4]?.children[0]?.variable;
    const masterContainerStyle = {
        ...getContainerStyle(mainContainer?.uuid, template, cards, card, variables, templateData, null),
        padding: "7px"
    }

    const childContainerStyle = {
        ...getContainerStyle(childContainer?.uuid, template, cards, card, variables, templateData, null),
        padding: '1px'
    }

    const childGreyContainerStyle = {
        ...getContainerStyle(childGreyContainer?.uuid, template, cards, card, variables, templateData, null),
        padding: '1px'
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3" style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null }}>
            <div className="flex items-center">
                <VariableValueExtractor variableId={mainText}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                <div className="child-container flex mx-1" style={{ ...childContainerStyle, flex: "0 0 auto", }}>
                    <div className='p-0.5'>
                        <VariableValueExtractor variableId={childText}
                            template={template}
                            cards={cards}
                            card={card}
                            variables={variables}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>
                </div>
            </div>

            <div className="flex items-center flex-wrap mt-2"  style={{ ...childGreyContainerStyle, flex: "0 0 auto", }}>
            </div>

            <div className="flexitems-center mt-2" >

                <div className="flex items-center">
                    <div className="me-1" style={{ marginTop: "-3px", marginLeft: "-5px" }}>
                        <ChaptersIcons name="Q" color='black' width={25} height={25} />
                    </div>
                    <VariableValueExtractor variableId={successText}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </div>
    
                {findModuleVariables(sucessModule, variables).map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={card}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            fullWidth={true}
                        />
                    )
                })}
           
                <div className="flex items-center flex-wrap my-2"  style={{ ...childGreyContainerStyle, flex: "0 0 auto", }}></div>
                
                <div className="flex items-center">
                    <div className="me-1" style={{ marginTop: "-3px", marginLeft: "-5px" }}>
                        <ChaptersIcons name="P" color='black' width={25} height={25} />
                    </div>
                    <VariableValueExtractor variableId={faillureText}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </div>
                
                <div style={{ marginTop: "-0.45rem" }}>
                        {findModuleVariables(faillureModule, variables).map((variable, index) => {
                            return (
                                <ModuleSelector
                                    key={index}
                                    variable={variable}
                                    card={card}
                                    cards={cards}
                                    deckId={deckId}
                                    templateData={templateData}
                                    fullWidth={true}
                                />
                            )
                        })}
                </div>
            </div>
        </div>
    )
}

export default YouMustEachMakeAttributeSkillCheck;