import { getContainerStyle } from '../../utils/variable-extractor';
import { ReactComponent as XIcon } from '../icons/DefaultIconSvgs/Warning_symbol.svg'
import VariableValueExtractor from "../utils/VariableValueExtractor";


const StorybookConclusionNoRecoveryBetweenChapters = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    // if (variables.variablesValues) {
    //     variables = variables.variablesValues;
    // }
    const masterContainerStyle = {
        ...getContainerStyle("94a6f248-eabc-4724-9f1d-238d484718bd", template, cards, card, variables, templateData, null),
        padding: "7px"
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3 flex flex-col full-w" style={{ ...masterContainerStyle}}>
            <div className='flex justify-center items-center'>
                 <XIcon height={20} width={20} fill="white"/>
                <div className='lineGreyCustom ml-1'>
                    <VariableValueExtractor variableId={"ab432af5-df8d-4d70-a96e-b223667766fc"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </div>
            </div>
            <div>
                <VariableValueExtractor variableId={"c44e0151-64b9-4aee-9177-4acfd0ca0bb1"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
            </div>          
        </div>
    )
}

export default StorybookConclusionNoRecoveryBetweenChapters;